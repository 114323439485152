/* eslint-disable jsx-a11y/alt-text */
import { AppBar, Grid, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import Scroll from "./Scroll";
import { Button, IconButton, MenuItem, Menu } from "@material-ui/core";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Link } from "react-router-dom";
import AppsIcon from "@material-ui/icons/Apps";
import dpalogo from "../asset/images/dpa_cloud_logo.png";
import dpalogosm from "../asset/images/dpa_cloud_logo_sm.png";
import InfoPulseLogo from "../asset/images/InfopulseLogo.png";

import summarizer from "../asset/images/menuicon/summarizer.png";
import audio from "../asset/images/menuicon/audiio.png";
import news from "../asset/images/menuicon/news.png";
import dataduplicate from "../asset/images/menuicon/duplication.png";
import text from "../asset/images/menuicon/text.png";
import dmaas from "../asset/images/menuicon/dmaas.png";
import rss from "../asset/images/menuicon/rss.png";
import pdf from "../asset/images/menuicon/pdftoexcel.png";

import profile from "../asset/images/menuicon/profile.png";
import account from "../asset/images/menuicon/account.png";
import support from "../asset/images/menuicon/support.png";
import feedback from "../asset/images/menuicon/feedback.png";
import logout from "../asset/images/menuicon/logout.png";

import Avatar from "@material-ui/core/Avatar";

import { Cookies } from "react-cookie";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSnackbar } from "./SnackbarContext";
import { useDispatch } from "react-redux";
import useAuth from "../hooks/useAuth";
import { logoutSuccess } from "../redux/slices/authJwt";
import { Home } from "@material-ui/icons";
import KeywordModal from "./Modals/keywordModal";
import CloudChartModal from "./Modals/CloudChartModal";
import { useHistory } from "react-router";
import config from "../utils/config";
import { useLocation } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
/* Navbar Section Start */
const token = localStorage.getItem("token");
const cookies = new Cookies();
/* Navbar Section Start */

export const Header = () => {
	const [anchorE2, setAnchorE2] = React.useState(null);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const matches = useMediaQuery("(max-width:992px)");
	const dispatch = useDispatch();
	const setSnackState = useSnackbar();
	const { isAuthenticated } = useAuth();
	const isMenuOpen = Boolean(anchorEl);
	const menuId = "primary-search-account-menu";
	const ITEM_HEIGHT = 48;
	const history = useHistory();
	const location = useLocation();
	const shouldRender = !location.pathname.includes("share");

	if (!shouldRender) {
		return null; //remove header from SHARE PAGE
	}

	const handleClick = (event) => {
		setAnchorE2(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorE2(null);
	};

	const handleProfileMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const logoutHandler = () => {
		setSnackState({
			severity: "success",
			message: "Logout Success",
			pathname: "/",
		});
		dispatch(logoutSuccess());
		localStorage.clear();
		cookies.remove("__idm_sec", {
			path: "/",
			domain: process.env.REACT_APP_COOKIE_DOMAIN,
		});
	};

	const handleRedirect = (e) => {
		if (e.target.parentElement.localName === "a") {
			var pathname = e.target.parentElement.id;
			window.location.replace(
				`${process.env.REACT_APP_DPA_CLOUD_ML}${pathname}`
			);
		}
	};

	const handleOauthLogin = () => {
		const CLIENT_ID = config.CLIENT_ID;
		const OAUTH_URL = config.OAUTH_URL;
		const CLIENT_URL = config.CLIENT_URL;
		const url = `${OAUTH_URL}/oauth-v2/login?eapp_id=${CLIENT_ID}&redirect_uri=${CLIENT_URL}&appName=infopulse`;
		window.location.href = url;
	};

	const handleRedirectDmass = () => {
		window.location.replace(`${process.env.REACT_APP_DMAAS_URL}`);
	};

	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMenuOpen}
			onClose={handleMenuClose}
			className="menuitem"
			PaperProps={{
				style: {
					width: "20ch",
					minHeight: "300px",
					overflow: "hidden",
				},
			}}
		>
			<Grid container>
				<Grid item xs={12} sm={6} onClickCapture={handleRedirect}>
					<Typography
						className="menuheading"
						variant="h6"
						noWrap
						style={{ color: "#303f9f" }}
					>
						Solutions
					</Typography>
					{/* id names given to links below are according to pathnames for specific mlapp */}
					<MenuItem onClick={handleMenuClose}>
						<Link onClick={handleOauthLogin}>
							<img src={account} className="iconimg" />
							<span>IDM</span>
						</Link>
					</MenuItem>
					<MenuItem>
						<Link id="text-summarizer">
							<img src={summarizer} className="iconimg" />
							<span>Text Summarizer</span>
						</Link>
					</MenuItem>
					<MenuItem>
						<Link id="audio-text">
							<img src={audio} className="iconimg" />
							<span>Audio To Text</span>
						</Link>
					</MenuItem>
					<MenuItem>
						<Link id="news-content-extractor">
							<img src={news} className="iconimg" />
							<span>News Content Extractor</span>
						</Link>
					</MenuItem>
					<MenuItem>
						<Link id="data-dedup">
							<img src={dataduplicate} className="iconimg" />
							<span>Data Deduplication</span>
						</Link>
					</MenuItem>
					<MenuItem>
						<Link id="text-classification">
							<img src={text} className="iconimg" />
							<span>Text Classification</span>
						</Link>
					</MenuItem>
					<MenuItem>
						<Link id="pdf-to-excel">
							<img src={pdf} className="iconimg" />
							<span>PDF to Excel</span>
						</Link>
					</MenuItem>
				</Grid>

				<Grid item xs={12} sm={6}>
					<Typography
						className="menuheading"
						variant="h6"
						noWrap
						style={{ color: "#fb7f2f" }}
					>
						Services
					</Typography>
					<MenuItem>
						<Link onClick={handleRedirectDmass}>
							<img src={dmaas} className="iconimg" /> <span> SmartPIPE </span>
						</Link>
					</MenuItem>
				</Grid>
			</Grid>
		</Menu>
	);

	return (
		<div className="grow">
			<Scroll showBelow={250} />
			<AppBar position="fixed" className="appnavbar">
				<Toolbar>
					{/* <Typography className="title" variant="h6" noWrap>
						<Link to="/">
							<img src={InfoPulseLogo} className="dpalogo logodesk " />
						</Link>
						<Link to="/">
							<img src={dpalogosm} className="dpalogo logomob" />
						</Link>
					</Typography> */}

					<Link to="/">
						<img src={InfoPulseLogo} className="dpalogo" />
					</Link>


					<div className="grow" />

					<div className="sectionDesktop">
						{/* <IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleProfileMenuOpen}
							color="inherit"
							className="iconapp"
						>
							<AppsIcon style={{ color: "#303f9f" }} />
						</IconButton> */}
						{/* <Tooltip title="Homepage">
							<Link to="/" variant="h6" className="logintitle">
								<img src={rss} className="iconimg" />
								<Typography className="nm">Infopulse</Typography>
								
							</Link>
						</Tooltip> */}

						<KeywordModal />
						<CloudChartModal />



						{isAuthenticated ? (
							<>
								<Link onClick={logoutHandler}>
									<Tooltip title="Log Out" placement="bottom" arrow >
										<IconButton size="medium">
											<LogoutIcon className="secondary" fontSize="small" />
										</IconButton>
									</Tooltip>
								</Link>
							</>
						) : (
							<>
								<Link
									className="logintitle"
									variant="h6"
									onClick={handleOauthLogin}
								>
									<Button variant="contained" className="btn-primary">Sign In</Button>
								</Link>
								{/* <Link
                  variant="h6"
                  className="logintitle"
                  onClick={() => history.push("/register")}
                >
                  Sign Up
                </Link> */}
							</>
						)}
					</div>
				</Toolbar>
			</AppBar>
			{/* {renderMenu} */}
		</div>
	);
};
/* Navbar Section Ends */
